export const TEST = 'TEST'
export const SET_TEXT = 'SET_TEXT'
export const CLEAR_TEXT = 'CLEAR_TEXT'
export const SET_SPEED = 'SET_SPEED'
export const SET_FONT_SIZE = 'SET_FONT_SIZE'
export const GET_ALL_SEGMENTS = 'GET_ALL_SEGMENTs'
export const REMOVE_SEGMENT = 'REMOVE_SEGMENT'
export const REMOVE_ALL_SEGMENT = 'REMOVE_ALL_SEGMENT'
export const SET_LINE_HEIGHT = 'SET_LINE_HEIGHT'
export const SET_SCROLL_SPEED = 'SET_SCROLL_SPEED'
export const SET_LETTER_SPACING = 'SET_LETTER_SPACING'
export const TOGGLE_FLIPPED = 'TOGGLE_FLIPPED'
export const SET_SCROLL_WIDTH = 'SET_SCROLL_WIDTH'
export const AUTH_USER = 'AUTH_USER'
export const REMOVE_USER = 'REMOVE_USER'
export const GET_ALL_PROMPTER = 'GET_ALL_PROMPTER'
export const CLEAR_ALL_PROMPTER = 'CLEAR_ALL_PROMPTER'
export const SET_PROMPTER_SLUG = 'SET_PROMPTER_SLUG'
export const SET_PROJECT_NAME = 'SET_PROMPTER_SLUG'
export const COPY_PROMPTER_OBJECT = 'COPY_PROMPTER_OBJECT'
export const CLEAR_PROMPTER_OBJECT = 'CLEAR_PROMPTER_OBJECT'
export const TOGGLE_UPDATE_BTN = 'TOGGLE_UPDATE_BTN'
export const HIDE_INSTRUCTION = 'HIDE_INSTRUCTION'
export const RESET_PROMPTER = 'RESET_PROMPTER'
export const SET_COLOR_SCHEME = 'SET_COLOR_SCHEME'
export const SET_FONT = 'SET_FONT'
export const ADD_SEGGMENT = 'ADD_SEGGMENT'
export const CLEAR_ALL_SEGMENTS = 'CLEAR_ALL_SEGMENTS'
export const MODIFY_SEGMENT = 'MODIFY_SEGMENT'
export const SET_TEXT_ALIGNMENT = 'SET_TEXT_ALIGNMENT'
